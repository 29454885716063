import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BG from "../assets/images/react.jpg"
import Typist from "react-typist"

const PageTitleContainer = styled.div`
  background: red;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-shadow: 0px 0px 8px #000;
    text-align: center;
    color: white;
  }
  p {
    font-size: 2rem;
    margin: 0 auto;
    color: white;
    font-weight: 200;
    span {
      font-weight: 500;
    }
    @media (min-width: 576px) {
      font-size: 3rem;
    }
  }
  img {
    position: fixed;
  }
  .Cursor {
    display: none;
  }
`
let CardOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`

export default () => {
  return (
    <Layout>
      <SEO title="Website Coming Soon" />
      <PageTitleContainer className="position-relative text-center p-0">
        <img src={BG} />
        <CardOverlay />
        <Typist className="content">
          <Container>
            <Row>
              <Col>
                <p className="my-4">
                  CRAIG<span>SHADDICK</span>
                </p>
                <h1 className="my-4">Web Developer in Brighton</h1>
                <h3 className="my-4">Website Coming Soon</h3>
              </Col>
            </Row>
          </Container>
        </Typist>
      </PageTitleContainer>
    </Layout>
  )
}
